import Vue from "vue";
import "number-to-locale-string";
import VueLazyload from 'vue-lazyload';
import loadingImg from '../../site/static/img/loading.png';

import { i18n } from "./i18n";

Vue.use(VueLazyload, { preLoad: 1.5, loading: loadingImg });
Vue.filter('capitalize', (value) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('i18n', (value, key) => i18n(key, value));
Vue.mixin({
    data() {
        return { i18n };
    }
});


function main() {

    const languageSwitch = document.getElementById('switch-language');
    languageSwitch.classList.remove('hidden');
    languageSwitch.addEventListener('click', () => {
        const newLang = languageSwitch.dataset.lang;
        document.cookie = 'nf_lang=' + newLang + ';path=/';
    }, true);

    const hero = document.querySelector('.hero');
    if (hero) {
        const img = window.innerWidth && window.innerWidth > 1500 ? '/img/view.jpg' : '/img/view_1500.jpg';
        const heroImg = new Image();
        heroImg.addEventListener('load', () => {
            hero.style.backgroundImage = 'url(' + img + ')';
            hero.classList.add('js-loaded');
        }, false);
        heroImg.src = img;
    }

    import('./carousel.js').then(carousel => carousel.createCarousel());
    import('./map.js');

    new Vue({
        el: '#calendar',
        components: {
            BookingFlow: () => import(/* webpackChunkName: "bookingflow" */ './components/BookingFlow.vue'),
        }
    });
}

function supportAllFeatures() {
    return window.Promise
        && window.Symbol
        && Array.prototype.findIndex
        && Array.prototype[Symbol.iterator]
        && Element.prototype.matches
        && Element.prototype.closest;
}

function loadScript(src, done) {
    var js = document.createElement('script');
    js.src = src;
    js.onload = function () {
        done();
    };
    js.onerror = function () {
        done(new Error('Failed to load script ' + src));
    };
    document.head.appendChild(js);
}

if (supportAllFeatures()) {
    main();
} else {
    loadScript("/polyfills.js", main);
}
